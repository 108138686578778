import { styled } from '@neui/core';
import { VStack } from '@neui/layout';
import {
  Checkbox,
  Headline,
  Input,
  Typography,
  symbols___info,
} from '@neui/styleguide-commerzbank';
import { useState } from 'react';

import { $t } from '@utils/i18n';
import { Step, useTracker } from '@utils/snowplowTracking';
import { Banner } from '@components/neui-components/molecules/Banner';
import { GA4TrackFeedbackButtonClick } from '@utils/tracking';
import { useIsPuk } from '@utils/isPukBoolean';
import {
  createResultObject,
  findSubstrings,
  replaceUmlauts,
} from '@utils/helpers/misc';
import { Button } from '@components/neui-components/atoms/Button';

import { CdsVideoBasicFeedbackProps } from './CdsVideoBasicFeedback';

const MAX_CHARACTER = 200;
const MIN_CHARACTER = 3;

const labels = [
  $t('VIDEO_FEEDBACK_ANSWER1'),
  $t('VIDEO_FEEDBACK_ANSWER2'),
  $t('VIDEO_FEEDBACK_ANSWER3'),
  $t('VIDEO_FEEDBACK_ANSWER4'),
];

const subLabels = [
  'unvollständig',
  'unverständlich',
  'undeutlich',
  'sonstiges',
];

const answers = new Set<string>();

type State = {
  checked: boolean[];
  inputText: string;
};

function isFormValid({ checked, inputText }: State): boolean {
  return (
    (checked.slice(0, 3).some((item) => item) &&
      isTextfieldDisabled(checked)) ||
    (checked[3] && !isTextfieldInvalid(inputText))
  );
}

function isTextfieldDisabled(checked: boolean[]): boolean {
  return !checked[3];
}

function isTextfieldInvalid(inputText: string): boolean {
  return inputText.length < MIN_CHARACTER || inputText.length > MAX_CHARACTER;
}

export type CdsVideoFeedbackProps = CdsVideoBasicFeedbackProps & {
  setDisplaySnackbar: (b: boolean) => void;
};

export const CdsVideoFeedback = ({
  context,
  setFeedbackOpen,
  setHasReviewed,
  setDisplaySnackbar,
}: CdsVideoFeedbackProps): React.ReactElement => {
  const { trackUserFeedback } = useTracker(CdsVideoFeedback.name);
  const { isPuk } = useIsPuk();

  const [checked, setChecked] = useState<boolean[]>(
    new Array(labels.length).fill(false),
  );
  const [inputText, setInputText] = useState<string>('');
  const [wasSentButtonClicked, setWasSentButtonClicked] =
    useState<boolean>(false);

  const remainingChars = MAX_CHARACTER - inputText.length;

  return (
    <VStack spacing={24}>
      <Headline type="h3">{$t('VIDEO_FEEDBACK_QUESTION')}</Headline>
      <Typography>{$t('FEEDBACK_QUESTION_SUBLINE')}</Typography>

      <VStack spacing={16}>
        {labels.map((item, idx) => (
          <StyledCheckbox
            key={idx}
            label={item}
            checked={checked[idx]}
            onCheckedChange={(value: boolean) => {
              checked[idx] = value;
              setChecked([...checked]);
              value ? answers.add(item) : answers.delete(item);
            }}
          />
        ))}
      </VStack>
      {!isTextfieldDisabled(checked) && (
        <>
          <Input
            value={inputText}
            invalid={wasSentButtonClicked && isTextfieldInvalid(inputText)}
            label={$t('FEEDBACK_ANSWER4_LABEL')}
            helperText={`${$t('FEEDBACK_ANSWER4_HELPERTEXT')}${remainingChars}`}
            placeholder={$t('FEEDBACK_ANSWER4_PLACEHOLDER')}
            onChange={(e) => setInputText(e.target.value)}
            maxLength={MAX_CHARACTER}
          />
          <StyledBanner icon={symbols___info} size="small" variant="solid">
            <Typography size={6}>{$t('PERSONAL_DATA_DISCLAIMER')}</Typography>
          </StyledBanner>
        </>
      )}
      <Button
        disabled={false}
        icon="interaction_arrows_arrowRight"
        onClick={() => {
          setWasSentButtonClicked(!wasSentButtonClicked);
          if (isFormValid({ checked, inputText }) === true) {
            const subStrings = findSubstrings(answers, subLabels);
            trackUserFeedback?.(
              Step.VideoSubmit,
              Array.from(answers),
              inputText,
              [context],
            );
            isPuk &&
              GA4TrackFeedbackButtonClick(
                'Video',
                $t('VIDEO_FEEDBACK_QUESTION'),
                subStrings.map((str) => replaceUmlauts(str)).join('|'),
                createResultObject(subLabels, subStrings),
              );
            setFeedbackOpen(false);
            setHasReviewed(true);
            setDisplaySnackbar(true);
          }
        }}
      >
        {$t('FEEDBACK_SUBMIT_BUTTON')}
      </Button>
    </VStack>
  );
};

const StyledCheckbox = styled(Checkbox, {
  minWidth: '24px !important',
});

const StyledBanner = styled(Banner, {
  wordBreak: 'break-word',
});
