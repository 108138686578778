import { keyframes, styled } from '@neui/core';

type BeneEntryPointSVGProps = {
  stopAnimation: boolean;
};

export default function BeneEntryPointSVG({
  stopAnimation,
}: BeneEntryPointSVGProps) {
  return (
    <Wrapper
      width="192"
      height="192"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={stopAnimation ? '' : 'bot'}>
        <path
          d="M73.627 70.9969C74.617 69.8169 76.187 68.4669 78.167 67.1169C80.947 65.2269 83.497 64.2269 85.297 63.7969"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M66.4268 75.9961V115.996L78.4268 119.996H102.427L114.427 115.996V75.9961C111.097 70.6661 103.227 59.9961 90.4268 59.9961C77.6268 59.9961 68.4268 70.6661 66.4268 75.9961Z"
          fill="#FFD700"
        />
        <path
          d="M114.427 92.0144V75.9844C111.097 70.6544 103.227 59.9844 90.4268 59.9844C77.6268 59.9844 68.4268 70.6544 66.4268 75.9844V92.0144H114.427Z"
          fill="#FFD700"
        />
        <path
          opacity="0.5"
          d="M114.427 92.0144V75.9844C111.097 70.6544 103.227 59.9844 90.4268 59.9844C77.6268 59.9844 68.4268 70.6544 66.4268 75.9844V92.0144H114.427Z"
          fill="white"
        />
        <path
          d="M116.127 74.9361C109.677 64.6061 101.517 58.8261 92.4268 58.0861V47.9961H88.4268V58.0861C75.7668 59.1061 66.7068 69.5661 64.5568 75.2961L64.4268 75.6361V115.996C64.4268 116.856 64.9768 117.616 65.7968 117.896L74.4268 120.776V116.556L68.4268 114.556V94.0261H112.427V114.556L106.437 116.556V120.776L115.057 117.906C115.877 117.636 116.427 116.866 116.427 116.006V75.4161L116.127 74.9261V74.9361ZM112.427 90.0161H68.4268V76.3761C70.5268 71.3261 79.0768 61.9961 90.4268 61.9961C100.837 61.9961 108.097 69.8061 112.427 76.5761V90.0261V90.0161Z"
          fill="black"
        />
        <path
          d="M78.4268 75.9961C80.6368 75.9961 82.4268 77.7861 82.4268 79.9961C82.4268 82.2061 80.6368 83.9961 78.4268 83.9961C76.2168 83.9961 74.4268 82.2061 74.4268 79.9961C74.4268 77.7861 76.2168 75.9961 78.4268 75.9961Z"
          fill="black"
        />
        <path
          d="M102.427 75.9961C104.637 75.9961 106.427 77.7861 106.427 79.9961C106.427 82.2061 104.637 83.9961 102.427 83.9961C100.217 83.9961 98.4268 82.2061 98.4268 79.9961C98.4268 77.7861 100.217 75.9961 102.427 75.9961Z"
          fill="black"
        />
        <path
          d="M102.427 117.996H78.4268V129.996H102.427V117.996Z"
          fill="#FFC700"
        />
        <path
          d="M102.427 117.996H90.417V129.996H102.427V117.996Z"
          fill="#F49300"
        />
        <path
          d="M102.427 117.996H78.4268V129.996H86.4268V133.996H94.4268V129.996H102.427V117.996ZM98.4268 125.996H82.4268V121.996H98.4268V125.996Z"
          fill="black"
        />
        <path
          d="M106.417 116.558L105.787 114.658L110.427 113.098L110.417 98.1484H112.417L112.427 114.538L106.417 116.558Z"
          fill="#F49300"
        />
        <path
          opacity="0.5"
          d="M70.457 94.0664H68.457V110.066H70.457V94.0664Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M83.4365 121.996V125.986"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.4268 91.2266L52.4268 94.5566V111.997H56.4268V97.4366L62.4268 95.4366V91.2266Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M72.287 74.0158L70.667 72.8458C71.327 71.9258 72.067 71.0458 72.837 70.2058C74.227 68.7158 75.817 67.3558 77.547 66.1758C78.917 65.2458 80.787 63.9658 84.207 62.9258L84.787 64.8358C81.657 65.7958 80.007 66.9158 78.677 67.8258C77.067 68.9258 75.597 70.1758 74.307 71.5658C73.587 72.3358 72.907 73.1658 72.287 74.0158Z"
          fill="white"
        />
        <path
          d="M74.3366 71.538L72.8066 70.248C73.9366 68.898 75.8666 67.348 76.8266 66.648C78.4366 65.468 79.7266 64.818 80.5866 64.388L81.0166 64.168L81.9466 65.938L81.4866 66.168C80.6866 66.568 79.4866 67.178 78.0166 68.258C76.8666 69.098 75.2266 70.478 74.3466 71.528L74.3366 71.538Z"
          fill="white"
        />
        <path
          d="M118.497 91.2461V95.4561L124.427 97.4361V111.996H128.427V94.5561L118.497 91.2461Z"
          fill="black"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.39 52.1004L119.886 42.8203L123.835 43.4571L122.339 52.7372L118.39 52.1004ZM136.556 52.5941L128.935 58.098L131.278 61.3406L138.898 55.8367L136.556 52.5941ZM134.288 69.2862L133.651 73.2352L142.931 74.7316L143.568 70.7826L134.288 69.2862Z"
          fill="black"
        />
      </g>
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.4316 149.996H52.4316V153.996H48.4316V149.996ZM120.427 149.996H56.4266V153.996H120.427V149.996ZM132.422 149.996H124.422V153.996H132.422V149.996Z"
        fill="#002E3C"
        id={stopAnimation ? '' : 'shadow'}
      />
    </Wrapper>
  );
}

const upAndDown = keyframes({
  '0%': { transform: 'translateY(0px)' },
  '25%': { transform: 'translateY(7px)' },
  '55%': { transform: 'translateY(-13px)' },
  '80%': { transform: 'translateY(7px)' },
  '100%': { transform: 'translateY(0px)' },
});

const colorChange = keyframes({
  '0%': { opacity: 0.3 },
  '25%': { opacity: 0.4 },
  '55%': { opacity: 0.2 },
  '80%': { opacity: 0.4 },
  '100%': { opacity: 0.3 },
});

const Wrapper = styled('svg', {
  minWidth: '192px',

  '& .bot': {
    animation: `${upAndDown} 5s infinite cubic-bezier(0.45, 0.00, 0.67, 1.00)`,
  },
  '& #shadow': {
    animation: `${colorChange} 5s infinite cubic-bezier(0.45, 0.00, 0.67, 1.00)`,
  },
});
