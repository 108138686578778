/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import { Metadata } from '@utils/DataFetcher';
import { LinkProps } from '@components/neui-components/atoms/Link';
import { ArticlePageProps } from 'pages/[...slug]';
import { DynamicLink } from '@components/DynamicLink/DynamicLink';
import { useTracker } from '@utils/snowplowTracking';
import { findFirstDataNode } from '@utils/helpers/misc';
import { GA4TrackLinkClick } from '@utils/tracking';
import { useIsPuk } from '@utils/isPukBoolean';

import { ParserComponentBasicProps } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';
import { isDomNode } from '../util';

const fixUrlMakeProps = (url: string): LinkProps => {
  let href = url;
  let isUrl = url.startsWith('https://');
  if (url.startsWith('www')) {
    href = `https://${url}`;
    isUrl = true;
  }
  if (url.startsWith('http://')) {
    isUrl = true;
    href = `https://${url.substring(7)}`;
  }

  return isUrl
    ? { href, target: '_self', rel: 'noopener' }
    : // probably a telephone number
      { href: url };
};

export type LinkRendererProps = {
  linksMetadata?: ArticlePageProps['textLinks'];
  basename: Metadata['basename'];
};

export default function CdsLinkRenderer({
  domNode,
  renderDomNode,
  linksMetadata,
  basename,
}: ParserComponentBasicProps & LinkRendererProps) {
  if (!isDomNode(domNode)) {
    return <></>;
  }

  const linkToken =
    (domNode.attribs && domNode.attribs['data-pcs-link-token']) || '';

  const url =
    linksMetadata &&
    linksMetadata?.find((link) => link.replacementToken === linkToken);

  // if direct child is img let it be handled by the ImgRenderer without wrapping
  if (domNode.children && domNode.children[0]?.name === 'img') {
    return <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />;
  }

  if (url) {
    const linkProps = fixUrlMakeProps(url.externalUrl);
    const { trackLinkClick } = useTracker(CdsLinkRenderer.name);
    const { isPuk } = useIsPuk();
    const linkLabel = findFirstDataNode(domNode) || '';
    return (
      <DynamicLink
        {...linkProps}
        target="_self"
        underlined
        onClick={() => {
          trackLinkClick?.(url.externalUrl, linkLabel);
          isPuk &&
            GA4TrackLinkClick(
              url.externalUrl,
              linkLabel,
              undefined,
              'Article Page',
            );
        }}
      >
        <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />
      </DynamicLink>
    );
  }

  return <React.Fragment />;
}
