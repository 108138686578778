import React from 'react';
import { styled } from '@neui/core';

import { ParserComponentBasicProps } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';

export default function CdsULRenderer({
  domNode,
  renderDomNode,
}: ParserComponentBasicProps) {
  return (
    <StyledUl>
      <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />
    </StyledUl>
  );
}

const StyledUl = styled('ul', {
  listStyle: 'none',
  counterReset: 'list-counter',
  display: 'table',
  borderCollapse: 'separate',
  borderSpacing: '0 16px',
  marginY: '-16px', // this corrects the external border-spacing
  '& > li': {
    '&:before': {
      content: '–',
    },
  },
});
