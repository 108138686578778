import { styled } from '@neui/core';
import { Stack, VStack } from '@neui/layout';
import {
  Typography,
  Headline,
  communication___chat,
} from '@neui/styleguide-commerzbank';
import { ReactNode, useState } from 'react';
import { Feature } from 'flagged';
import { SelfDescribingJson } from '@snowplow/browser-tracker';

import { IconLink } from '@components/neui-components/atoms/IconLink';
import { BannerContainer } from '@components/neui-components/atoms/BannerContainer';
import { PortalData, useTracker } from '@utils/snowplowTracking';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';

import { selectChatActions, useChatStore } from './store/store';
import BeneEntryPointSVG from './BeneEntryPointSVG';

const StyledBannerContainer = styled(BannerContainer, {
  padding: 24,
  height: 'fit-content',
});

const MsgArrow = styled('span', {
  width: 16,
  height: 16,
  backgroundImage: 'url(/service/icons/Pointer.svg)',
  backgroundRepeat: 'no-repeat',
  transform: 'rotate(90deg)',
  marginBottom: '-9px',
  '@sm': {
    transform: 'rotate(0deg)',
    marginBottom: '0px',
  },
});

type ChatEntryPointProps = {
  headline: string;
  text: string;
  extraLinks?: ReactNode;
  portalContext: SelfDescribingJson<PortalData>;
};

export function ChatEntryPoint({
  headline,
  text,
  extraLinks,
  portalContext,
}: ChatEntryPointProps) {
  const { setIsChatOpen } = useChatStore(selectChatActions);
  const { trackLinkClick } = useTracker(ChatEntryPoint.name);
  const [stopAnimation, setStopAnimation] = useState(false);
  const product: string = portalContext.data.product;

  return (
    <Feature name="enableChat">
      <Stack
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems={'center'}
      >
        <BeneEntryPointSVG stopAnimation={stopAnimation} />

        <MsgArrow />
        <StyledBannerContainer>
          <VStack alignItems={'flex-start'} spacing={16}>
            <VStack>
              <Headline type={'h5'} renderAs={'span'}>
                {headline}
              </Headline>
              <Typography size={7} weight={'book'}>
                {text}
              </Typography>
            </VStack>
            <Stack
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'flex-start', sm: 'center' }}
              spacing={{ base: 16, sm: 32 }}
              justifyContent={'center'}
            >
              <IconLink
                icon={communication___chat}
                iconPosition="left"
                onClick={() => {
                  setStopAnimation(true);
                  setIsChatOpen(true);
                  trackLinkClick?.('Chat started', 'ChatEntryPoint', [], '', [
                    portalContext,
                  ]);
                  GA4TrackTeaserCtaClick(
                    'CTA Contact Teaser',
                    headline,
                    'Chat beginnen',
                    undefined,
                    undefined, // needs further definiton. OLD -> product.includes('lp') ? 'IndexPage' : 'Article',
                  );
                }}
              >
                Chat beginnen
              </IconLink>
              {extraLinks && extraLinks}
            </Stack>
          </VStack>
        </StyledBannerContainer>
      </Stack>
    </Feature>
  );
}
