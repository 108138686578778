import { forwardRef, PropsWithChildren, ReactNode } from 'react';
import { CSSProps, styled, VariantProps } from '@neui/core';
import { Box, VStack } from '@neui/layout';

import { Card, CardProps } from '@components/neui-components/atoms/Card';

const CardBody = styled(VStack, {
  height: '100%',
  padding: '$space$component-4',
  gap: '$space$component-7',
  '@md': {
    padding: '$space$component-6',
    gap: '$space$layout-4',
  },
});

const CardTop = styled(Box, {});

const CardFooter = styled(Box, {});

export interface TeaserCardProps
  extends VariantProps<typeof CardBody>,
    PropsWithChildren<CSSProps>,
    Partial<CardProps> {
  picture?: ReactNode;
  footer?: ReactNode;
}

export const TeaserCard = forwardRef<HTMLDivElement, TeaserCardProps>(
  (props, forwardedRef) => {
    const { children, picture, footer, css, ...rest } = props;
    return (
      <Card ref={forwardedRef} {...rest}>
        {picture && picture}
        <CardBody css={css} justifyContent={'space-between'}>
          <CardTop>{children}</CardTop>
          {footer && <CardFooter>{footer}</CardFooter>}
        </CardBody>
      </Card>
    );
  },
);

TeaserCard.displayName = 'TeaserCard';
